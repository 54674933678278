import React from "react"
import { graphql } from "gatsby"
import BlockContent from "../components/block-content"
import Container from "../components/main/container"
import GraphQLErrorList from "../components/main/graphql-error-list"
import SEO from "../components/main/seo"
import Layout from "../containers/layout"
import { responsiveTitle1 } from "../styles/typography.module.css"

export const query = graphql`
  query ContactPageQuery {
    page: sanityPage(_id: { eq: "aboutus" }) {
      title
      _rawBody
    }
  }
`

const ContactPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data.page

  if (!page) {
    throw new Error(
      'Missing "Contact" page data. Open the studio at http://localhost:3333 and add "Contact" page data'
    )
  }

  return (
    <Layout>
      <SEO title={page.title} />
      <Container>
        <h1 className={responsiveTitle1}>{page.title}</h1>
        <BlockContent blocks={page._rawBody || []} />
      </Container>
    </Layout>
  )
}
ContactPage.defaultProps = {
  data: {
    page: {
      title: "No title"
    }
  }
}
export default ContactPage
